import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationHR from "./locales/hr/translation.json";
const resources = {
  en: {
    translation: translationEN
  },
  hr: {
    translation: translationHR
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    interpolation: {
      escapeValue: false
    },
    fallbackLng: "en"
  });

export default i18n;
