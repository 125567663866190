import React, { ChangeEvent } from "react";

export interface ITextInputProps {
  type?: string;
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: ChangeEvent<HTMLInputElement>) => void;
  value: any;
  name: string;
}

const TextInput: React.FC<ITextInputProps> = ({
  type = "text",
  placeholder,
  ...rest
}) => {
  return (
    <div className="text-input-container">
      <input
        className="text-input"
        type={type}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

export { TextInput };
