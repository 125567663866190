import React, { useState, useEffect } from 'react';
import { Footer } from 'components/Footer';
import { Services } from 'components/Services';
import { useTranslation } from 'react-i18next';
import { locations } from 'components/Home';
import Flag from 'static/SVG/Locations/Flag.svg';
import Marker from 'static/SVG/Locations/Marker.svg';
import { ReactSVG } from 'react-svg';
import { GoogleMap } from 'components/GoogleMap';
import { scrollToTop } from 'utils';
import Helmet from 'react-helmet';

const headquarter: { coordinates: { lat: number; lng: number } } = {
  coordinates: { lat: 43.294565, lng: 17.022567 },
};
export interface ILocationsProps {}

const Locations: React.FC<ILocationsProps> = ({}) => {
  const [mapCenter, setMapCenter] = useState<any>({
    lat: 43.364284,
    lng: 17.048217,
  });
  const [mapZoom, setMapZoom] = useState<number>(9);
  const [locationPressed, setLocationPressed] = useState<any>(null);

  const { t } = useTranslation();
  useEffect(() => {
    scrollToTop();
  }, []);
  const mapRef = document.getElementById('map');
  return (
    <div className="fullWidth">
      <div className="locations">
        <div className="section-top">
          <div className="locations-content">
            <h1>{t('locations.heading1')}</h1>
            <h2>{t('locations.heading2')}</h2>
          </div>
        </div>
        <main id="map" className="main-container">
          <div className="top">
            <div className="content">
              <span className="item">
                <ReactSVG src={Marker} />
                <span>{t('locations.headquartersLocation')}</span>
              </span>
              <span className="divider" />
              <span className="item">
                <ReactSVG src={Flag} />
                <span>{t('locations.pickDropLocations')}</span>
              </span>
            </div>
          </div>
          <div className="map-container">
            <div className="content">
              <GoogleMap
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDUZYvLy4PV1nxHmDxdS7Bd2YdffTtniUk"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                locations={locations}
                headquarter={headquarter}
                mapCenter={mapCenter}
                zoom={mapZoom}
                locationPressed={locationPressed}
              />
            </div>
          </div>
          <div className="bottom">
            <div className="content">
              {locations.map((item: any, i: number) => {
                if (item.pickUp && item.dropOff) {
                  return (
                    <div
                      key={i}
                      className="location"
                      onClick={() => {
                        setLocationPressed(item);
                        mapRef?.scrollIntoView();
                      }}
                    >
                      <span className="icon">
                        <ReactSVG src={Flag} />
                      </span>
                      <span className="name">{item.name}</span>
                      <span className="fee">
                        {item.fee ? t('locations.fee') : t('locations.free')}
                      </span>
                    </div>
                  );
                }
              })}

              {/* <div className="just-drop-off">
                <p>{t("locations.onlyDropOff")}</p>
                {locations.map((item: any, i: number) => {
                  if (!item.pickUp && item.dropOff) {
                    return (
                      <div
                        key={i}
                        className="location"
                        onClick={() => {
                          setLocationPressed(item);
                          mapRef?.scrollIntoView();
                        }}
                      >
                        <span className="icon">
                          <ReactSVG src={Flag} />
                        </span>
                        <span className="name">{item.name}</span>
                        <span className="fee">
                          {item.fee ? t("locations.fee") : t("locations.free")}
                        </span>
                      </div>
                    );
                  }
                })}
              </div> */}
            </div>
          </div>
        </main>

        <Services />
        <Footer />
      </div>
      <Helmet>
        <title>Seven - Locations</title>
        <meta
          name="description"
          content="Rent a car seven offers a lot of different places where you can pick up or drop off rented car. Split, Omiš, Airport Split, Duće, Makarska, Tučepi, Podgora, Promajna, Baška voda, Brela, Živogošće"
        ></meta>
      </Helmet>
    </div>
  );
};

export { Locations };
