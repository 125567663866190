import React from 'react';
import { Link } from 'react-router-dom';
import Envelope from 'static/SVG/Footer/Mail.svg';
import Phone from 'static/SVG/Footer/Phone.svg';
import { ReactSVG } from 'react-svg';
export interface IFooterProps {}

const Footer: React.FC<IFooterProps> = ({}) => {
  return (
    <footer className="fullWidth">
      <div className="footer">
        <div className="content">
          <div className="section-left">
            <span className="companyName">CAR SEVENTH j.d.o.o.</span>
            <span className="link">
              <Link to="/contact">Contact</Link>
            </span>
            <span className="link">
              <Link to="/termsOfService">Terms of service</Link>
            </span>
            <span className="link">
              <Link to="/privacyPolicy">Privacy Policy</Link>
            </span>
          </div>
          <div className="section-right">
            <span className="contact">
              <ReactSVG src={Phone} />
              <a href="tel:+385 97 7577 777">+385 97 7577 777</a>
            </span>
            <span className="contact">
              <ReactSVG src={Envelope} />
              <a href="mailto:info@rentacarseven.com">info@rentacarseven.com</a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
