import React, { useState } from "react";
import { ReactComponent as Logo } from "static/SVG/Logo/Logo.svg";
import { ReactComponent as MenuGreen } from "static/SVG/Header/MenuGreen.svg";
import { ReactComponent as MenuWhite } from "static/SVG/Header/MenuWhite.svg";
import { ReactComponent as ArrowDownWhite } from "static/SVG/Header/ArrowDownWhite.svg";
import { ReactComponent as ArrowUpWhite } from "static/SVG/Header/ArrowUpWhite.svg";
import { HashLink as Link } from "react-router-hash-link";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

export interface IHeaderProps {}

const Header: React.FC<IHeaderProps> = () => {
  const [isNavVisible, setIsNavVisible] = useState<boolean>(false);
  const [showLanguageSelection, setShowLanguageSelection] = useState<boolean>(
    false
  );

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const setLanguage = (lng: "hr" | "en") => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  };

  return (
    <header className="header-container fullWidth">
      <div className="header-content">
        <div
          onClick={() => {
            history.push("/");
            window.scroll(0, 0);
          }}
          className="logo"
        >
          <Logo />
        </div>
        <div className="hamburger">
          {!isNavVisible && <MenuWhite onClick={() => setIsNavVisible(true)} />}
          {isNavVisible && <MenuGreen onClick={() => setIsNavVisible(false)} />}
        </div>
        <nav className="nav-desktop">
          <ul>
            <li>
              <Link to="/#home">{t("menu.home")}</Link>
            </li>
            <li>
              <Link to="/#fleet">{t("menu.fleet")}</Link>
            </li>
            <li>
              <Link to="/locations">{t("menu.locations")}</Link>
            </li>
            <li>
              <Link to="/contact">{t("menu.contact")}</Link>
            </li>
            <li>
              <div className="language-selection-dropdown">
                <button
                  onClick={() =>
                    setShowLanguageSelection(!showLanguageSelection)
                  }
                  className="language-option-selected"
                >
                  {i18n.language === "hr" && "HRV"}
                  {i18n.language === "en" && "ENG"}

                  <span>
                    {showLanguageSelection ? (
                      <ArrowUpWhite />
                    ) : (
                      <ArrowDownWhite />
                    )}
                  </span>
                </button>

                <button
                  onClick={() => {
                    setLanguage(i18n.language === "hr" ? "en" : "hr");
                    setShowLanguageSelection(false);
                  }}
                  className={
                    showLanguageSelection
                      ? "language-option"
                      : "language-option-hidden"
                  }
                >
                  {i18n.language === "en" && "HRV"}
                  {i18n.language === "hr" && "ENG"}
                </button>
              </div>
            </li>
          </ul>
        </nav>
      </div>
      <div
        className={`nav-mobile ${isNavVisible ? "nav-visible" : "nav-hidden"}`}
      >
        <nav className="nav-mobile-content medium-16">
          <ul>
            <li>
              <Link onClick={() => setIsNavVisible(false)} to="/#home">
                {t("menu.home")}
              </Link>
            </li>
            <li>
              <Link onClick={() => setIsNavVisible(false)} to="/#fleet">
                {t("menu.fleet")}
              </Link>
            </li>
            <li>
              <Link onClick={() => setIsNavVisible(false)} to="/locations">
                {t("menu.locations")}
              </Link>
            </li>
            <li>
              <Link onClick={() => setIsNavVisible(false)} to="/contact">
                {t("menu.contact")}
              </Link>
            </li>
            <li className="language-options">
              <button
                className={
                  i18n.language === "hr" ? "language-option-selected" : ""
                }
                onClick={() => setLanguage("hr")}
              >
                HRV
              </button>
              <div className="distancer"></div>
              <button
                onClick={() => setLanguage("en")}
                className={
                  i18n.language === "en" ? "language-option-selected" : ""
                }
              >
                ENG
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export { Header };
