import React, { useState } from "react";
import { Login } from "../Admin";
import { firebase } from "../../Firebase";
import { Loading } from "components/Loading";

export const withPermission = Component => {
  return () => {
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        setUser(user);
        setIsLoading(false);
      } else {
        setUser(null);
        setIsLoading(false);
      }
    });
    const handleLogin = async (email, password) => {
      await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => setError(null))
        .catch(err => setError(err.message));
    };
    if (isLoading) {
      return (
        <Loading fullscreen text="Checking app state ..." background="#000" />
      );
    }
    if (user) {
      return <Component />;
    } else {
      return <Login handleLogin={handleLogin} error={error} />;
    }
  };
};
