import React from "react";
import { Loading } from "components/Loading";

export interface IModalProps {
  isVisible: boolean;
  message: string;
  success?: boolean;
  hideModal?: (params?: any) => void;
  submiting?: boolean;
}

const Modal: React.FC<IModalProps> = ({
  isVisible,
  message,
  success,
  hideModal,
  submiting
}) => {
  return (
    <div
      className={`modal ${isVisible ? "modal-visible" : "modal-hidden"}`}
      onClick={() => hideModal && hideModal()}
    >
      <div className="modal-backdrop">
        <div className="modal-content animated slideInUp">
          {!submiting && (
            <>
              {success ? <Success /> : <Reject />}
              <p>{message}</p>
            </>
          )}
          {submiting && (
            <>
              <Loading />
              <p>{message}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { Modal };

const Success: React.FC<any> = () => {
  return (
    <svg
      className="svg-status"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130.2 130.2"
    >
      <circle
        className="path circle"
        fill="none"
        stroke="#73AF55"
        strokeWidth="6"
        strokeMiterlimit="10"
        cx="65.1"
        cy="65.1"
        r="62.1"
      />
      <polyline
        className="path check"
        fill="none"
        stroke="#73AF55"
        strokeWidth="6"
        strokeLinecap="round"
        strokeMiterlimit="10"
        points="100.2,40.2 51.5,88.8 29.8,67.5 "
      />
    </svg>
  );
};

const Reject: React.FC<any> = () => {
  return (
    <svg
      className="svg-status"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130.2 130.2"
    >
      <circle
        className="path circle"
        fill="none"
        stroke="#f39422"
        strokeWidth="6"
        strokeMiterlimit="10"
        cx="65.1"
        cy="65.1"
        r="62.1"
      />
      <line
        className="path line"
        fill="none"
        stroke="#f39422"
        strokeWidth="6"
        strokeLinecap="round"
        strokeMiterlimit="10"
        x1="34.4"
        y1="37.9"
        x2="95.8"
        y2="92.3"
      />
      <line
        className="path line"
        fill="none"
        stroke="#f39422"
        strokeWidth="6"
        strokeLinecap="round"
        strokeMiterlimit="10"
        x1="95.8"
        y1="38"
        x2="34.4"
        y2="92.2"
      />
    </svg>
  );
};
