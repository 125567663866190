import React, { useState, useEffect } from 'react';

import { Footer } from 'components/Footer';
import { Services } from 'components/Services';
import CarImage from '../../static/Photo/CarMain.png';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Dropdown } from 'components/Dropdown';
import Datepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import hr from 'date-fns/locale/hr';
import { ReactSVG } from 'react-svg';
import Calendar from 'static/SVG/Reservation/Calendar.svg';
import { Fleet } from 'components/Fleet';
import { ICar } from 'components/Fleet/Car';
import { firebase } from '../../Firebase';
import { Transfers } from 'components/Transfers';

registerLocale('hr', hr);
export const locations: {
  id: number;
  name: string;
  fee: boolean;
  pickUp: boolean;
  dropOff: boolean;
  coordinates: { lat: number; lng: number };
}[] = [
  {
    id: 1,
    name: 'Brela',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.3689475, lng: 16.9334935 },
    fee: false,
  },
  {
    id: 2,
    name: 'Baška voda',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.3582543, lng: 16.9482271 },
    fee: false,
  },
  {
    id: 3,
    name: 'Makarska',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.2960669, lng: 17.0165161 },
    fee: false,
  },
  {
    id: 4,
    name: 'Tučepi',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.2645809, lng: 17.0575522 },
    fee: false,
  },
  {
    id: 5,
    name: 'Podgora',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.24261, lng: 17.07693 },
    fee: false,
  },

  {
    id: 6,
    name: 'Drašnice',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.2190797, lng: 17.1092223 },
    fee: false,
  },
  {
    id: 7,
    name: 'Podstrana',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.484459, lng: 16.55274 },
    fee: true,
  },
  {
    id: 8,
    name: 'Duće (hotel Plaža)',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.4414389, lng: 16.6570579 },
    fee: true,
  },
  {
    id: 9,
    name: 'Omiš (hotel Plaža)',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.4427652, lng: 16.692435 },
    fee: true,
  },
  {
    id: 10,
    name: 'Omiš (downtown)',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.4446927, lng: 16.6912891 },
    fee: true,
  },

  {
    id: 11,
    name: 'Igrane',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.1965098, lng: 17.1394441 },
    fee: true,
  },
  {
    id: 12,
    name: 'Živogošće',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.188147, lng: 17.1605863 },
    fee: true,
  },
  {
    id: 13,
    name: 'Drvenik',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.1559487, lng: 17.2472622 },
    fee: true,
  },
  {
    id: 14,
    name: 'Gradac',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.1064526, lng: 17.3416928 },
    fee: true,
  },
  {
    id: 15,
    name: 'Ploče',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.0525723, lng: 17.4362496 },
    fee: true,
  },

  {
    id: 16,
    name: 'Imotski',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.4471115, lng: 17.2179703 },
    fee: true,
  },
  {
    id: 17,
    name: 'Mostar (BiH)',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.3416104, lng: 17.8030427 },
    fee: true,
  },
  {
    id: 18,
    name: 'Međugorje (BiH)',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.200258, lng: 17.663416 },
    fee: true,
  },
  {
    id: 19,
    name: 'Split',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.5116383, lng: 16.4399659 },
    fee: true,
  },
  {
    id: 20,
    name: 'Split (Airport)',
    pickUp: true,
    dropOff: true,
    coordinates: { lat: 43.5397753, lng: 16.3002242 },
    fee: true,
  },
];

export interface IHomeProps {
  history: any;
}

const Home: React.FC<IHomeProps> = ({ history }) => {
  const { t, i18n } = useTranslation();
  const [carSelected, setCarSelected] = useState<string | null>(null);
  const [pickupLocation, setPickupLocation] = useState<string | null>(null);
  const [pickupDate, setPickupDate] = useState<Date | null>(null);
  const [returnDate, setReturnDate] = useState<Date | null>(null);
  const [carError, setCarError] = useState<string | null>(null);
  const [cars, setCars] = useState<ICar[]>([]);

  useEffect(() => {
    const db = firebase.firestore();
    db.collection('cars')
      .get()
      .then((querySnapshot) => {
        let carsFromDB: ICar[] = [];
        querySnapshot.forEach((doc) => {
          const car = doc.data();
          carsFromDB.push({
            name: car.name,
            price: car.price,
            id: doc.id,
            image: car.image,
            type: car.type,
            manual: car.manual,
            automatic: car.automatic,
            airConditioning: car.airConditioning,
            persons: car.persons,
            bags: car.bags,
            sorting: car.sorting,
          });
        });
        const sorted = carsFromDB.sort((a, b) => a.sorting - b.sorting);
        setCars([...sorted]);
      });
  }, []);

  const handleReservationSubmit = () => {
    if (!carSelected) {
      setCarError(t('home.reservation.carNotSelected'));
    }

    //redirect to get a qoute with car id and rest of parameters

    const car = cars.filter((item: ICar) => item.name === carSelected)[0];

    if (car) {
      history.push({
        pathname: '/GetQuote/' + car.id,
        params: {
          pickupDate,
          returnDate,
          pickupLocation,
        },
      });
    }
  };

  const inputContainers = document.getElementsByClassName(
    'react-datepicker-wrapper'
  );
  if (inputContainers && inputContainers.length > 0) {
    inputContainers[0].children[0].children[0].setAttribute('readonly', 'true');
    inputContainers[1].children[0].children[0].setAttribute('readonly', 'true');
  }
  return (
    <div className='fullWidth home' id='home'>
      <section className='section-top'>
        <div className='section-top-content'>
          <div className='car-container'>
            <div className='img-container animated05 zoomIn'>
              <img src={CarImage} alt='Car header' />
            </div>
          </div>
          <div className='text-reservation-wrap'>
            <div className='text-container animated zoomInDown'>
              <h1>{t('home.top.headingOne')}</h1>
              <h1 className='green'>{t('home.top.headingTwo')}</h1>
            </div>
            <div className='reservation-container animated05 zoomIn'>
              <div className='item'>
                <Dropdown
                  onFocus={() => setCarError(null)}
                  error={carError}
                  onSelect={(value: string) => setCarSelected(value)}
                  placeholder={t('home.reservation.pickCar')}
                  options={cars.map((item: ICar) => item.name)}
                />
              </div>
              <div className='item'>
                <Dropdown
                  onSelect={(value: string) => setPickupLocation(value)}
                  placeholder={t('home.reservation.pickupLocation')}
                  options={locations
                    .filter((item: any) => item.pickUp)
                    .map((item: any) => item.name)}
                />
              </div>
              <div className='item'>
                <span className='calendar-icon'>
                  <ReactSVG src={Calendar} />
                </span>
                <div>
                  <Datepicker
                    selected={pickupDate}
                    showTimeSelect
                    dateFormat='dd/MM/yyyy HH:mm'
                    timeFormat='HH:mm'
                    onChange={(date: Date | null) => {
                      if (returnDate && date && date > returnDate) {
                        setReturnDate(null);
                      }
                      setPickupDate(date);
                    }}
                    placeholderText={t('home.reservation.pickupDate')}
                    minDate={new Date()}
                    onFocus={() => {}}
                  />
                </div>
              </div>
              <div className='item'>
                <span className='calendar-icon'>
                  <ReactSVG src={Calendar} />
                </span>
                <div>
                  <Datepicker
                    selected={returnDate}
                    showTimeSelect
                    dateFormat='dd/MM/yyyy HH:mm'
                    timeFormat='HH:mm'
                    onChange={(date: Date | null) => {
                      setReturnDate(date);
                    }}
                    placeholderText={t('home.reservation.returnDate')}
                    minDate={pickupDate ? pickupDate : new Date()}
                  />
                </div>
              </div>
              <div className='item'>
                <button
                  className='next'
                  onClick={() => handleReservationSubmit()}
                >
                  {t('home.reservation.next')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='bottom-bar'></div>
      </section>

      <section className='section-contact'>
        <div>
          <h2>
            {t('home.contact.feelFree')}{' '}
            {
              <span className='green'>
                {i18n.language === 'hr' && <br className='break' />}
                <a className='tel' href='tel:+385 97 7577 777'>
                  +385 97 7577 777
                </a>
              </span>
            }
            <br />
            {t('home.contact.or')}{' '}
            {
              <span id='fleet' className='green'>
                {t('home.contact.sendQuote')}
              </span>
            }{' '}
            {t('home.contact.respond')}
          </h2>
        </div>
      </section>
      <section className='section-fleet'>
        <Fleet navigation={history} />
      </section>
      <Transfers />
      <Services />
      <Footer />
      <Helmet>
        <title>Seven - Rent a car</title>
        <meta
          name='description'
          content='Rent a car Seven is small rent a car in company located at Makarska Riviera. We offer great prices and great deals every day. Flexible pickup and return locations. People transfers. Rent a driver. '
        ></meta>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
        />
      </Helmet>
    </div>
  );
};

export { Home };
