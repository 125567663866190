import React, { useEffect, useState } from 'react';
import { Footer } from 'components/Footer';
import { Services } from 'components/Services';
import { scrollToTop } from 'utils';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import Envelope from 'static/SVG/Contact/Envelope.svg';
import Mail from 'static/SVG/Contact/Mail.svg';
import Phone from 'static/SVG/Contact/Phone.svg';
import { TextInput } from 'components/TextInput';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Modal } from 'components/Modal';
import Helmet from 'react-helmet';

export interface IContactProps {}

const Contact: React.FC<IContactProps> = ({}) => {
  const { t } = useTranslation();
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    scrollToTop();
  }, []);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t('validationMessage.email'))
      .required(t('validationMessage.required')),
    message: Yup.string().required(t('validationMessage.required')),
  });
  return (
    <div className='fullWidth'>
      <div className='contact'>
        <div className='section-top'>
          <div className='contact-content'>
            <h1>{t('contact.heading1')}</h1>
            <h2>{t('contact.heading2')}</h2>
          </div>
        </div>
        <main className='main-container'>
          <div className='contact-content'>
            <div className='about'>
              <h3>{t('contact.about.heading')}</h3>
              <p>{t('contact.about.text1')}</p>
              <p>{t('contact.about.text2')}</p>
              <p>{t('contact.about.text3')}</p>
            </div>
            <div className='info'>
              <h3>{t('contact.info.heading')}</h3>
              <div className='data'>
                <div>
                  <span>CAR SEVENTH j.d.o.o.</span>

                  <span>
                    <span className='margin-right'>
                      {t('contact.info.owner')}
                    </span>
                    <span>Mijo Jović</span>
                  </span>
                </div>

                <div>
                  <span>
                    <span className='margin-right'>
                      {t('contact.info.street')}
                    </span>
                    <span>Kralja zvonimira 11</span>
                  </span>

                  <span>
                    <span className='margin-right'>
                      {t('contact.info.city')}
                    </span>
                    <span>Makarska</span>
                  </span>
                </div>
                <div>
                  <span>
                    <span className='margin-right'>
                      {t('contact.info.country')}
                    </span>
                    <span>Hrvatska</span>
                  </span>
                  <span>
                    <span className='margin-right'>
                      {t('contact.info.oib')}
                    </span>
                    <span>55559281940</span>
                  </span>
                </div>

                <div>
                  <span>
                    <span className='icon margin-right'>
                      <ReactSVG src={Phone} />
                    </span>

                    <span>
                      <a className='link' href='tel:+385 97 7577 777'>
                        +385 97 7577 777
                      </a>
                    </span>
                  </span>

                  <span>
                    <span className='icon margin-right'>
                      <ReactSVG src={Mail} />
                    </span>
                    <span>
                      <a className='link' href='mailto:info@rentacarseven.com'>
                        info@rentacarseven.com
                      </a>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className='contact' id='mobile-scrollTo'>
              <h3>{t('contact.form.heading')}</h3>
              <Formik
                initialValues={{
                  name: '',
                  email: '',
                  subject: '',
                  message: '',
                }}
                onSubmit={async (values, { resetForm }) => {
                  await fetch(
                    'https://us-central1-rent-a-car-seven.cloudfunctions.net/app/contactUs',
                    {
                      method: 'POST',
                      body: JSON.stringify(values),
                      headers: {
                        'Content-Type': 'application/json',
                      },
                    }
                  )
                    .then((res) => res.json())
                    .then((data) => {
                      if (data.success === true) {
                        resetForm();
                        setModalVisible(true);
                        setSuccess(true);
                      }
                    })
                    .catch(() => {
                      setModalVisible(true);
                      setSuccess(false);
                    });
                }}
                validationSchema={validationSchema}
              >
                {(props) => (
                  <div className='form'>
                    <div className='envelope-mobile'>
                      {' '}
                      <ReactSVG src={Envelope} />
                    </div>
                    <div className='left'>
                      <div className='row'>
                        <div className='input-wrap'>
                          <TextInput
                            name='name'
                            placeholder={t('contact.form.name')}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.name}
                          />
                        </div>

                        <div className='input-wrap'>
                          <TextInput
                            name='email'
                            placeholder={t('contact.form.emailAdress')}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.email}
                          />
                          {props.errors.email && props.touched.email && (
                            <span className='error-message'>
                              {props.errors.email}
                            </span>
                          )}
                        </div>
                        <div className='input-wrap'>
                          <TextInput
                            name='subject'
                            placeholder={t('contact.form.subject')}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.subject}
                          />
                        </div>
                      </div>
                      <div className='text-area-wrap'>
                        <div className='text-area'>
                          <textarea
                            className='message'
                            name='message'
                            value={props.values.message}
                            onChange={props.handleChange}
                            placeholder={t('contact.form.message')}
                          />
                        </div>
                        {props.errors.message && props.touched.message && (
                          <span className='error-message'>
                            {props.errors.message}
                          </span>
                        )}
                      </div>
                      <div className='btn-holder'>
                        <button
                          onClick={async () => {
                            if (!props.isSubmitting) {
                              await props.validateForm().then((errors) => {
                                const keys = Object.keys(errors);
                                if (keys.length > 0) {
                                  if (window.innerWidth < 1249) {
                                    const position = document.getElementById(
                                      'mobile-scrollTo'
                                    );
                                    if (position) {
                                      window.scroll(0, position.offsetTop - 60);
                                    }
                                  }
                                }
                              });
                              props.handleSubmit();
                            }
                          }}
                          className='send'
                        >
                          {props.isSubmitting
                            ? t('contact.form.submitting')
                            : t('contact.form.send')}
                        </button>
                      </div>
                    </div>
                    <div className='right'>
                      <ReactSVG src={Envelope} />
                    </div>
                    <Modal
                      submiting
                      isVisible={props.isSubmitting}
                      message={t('validationMessage.subbmitingRequest')}
                    />
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </main>
        <Services />
        <Footer />
        <Modal
          isVisible={isModalVisible}
          message={
            success
              ? t('validationMessage.emailSuccess')
              : t('validationMessage.emailReject')
          }
          success={success}
          hideModal={() => setModalVisible(false)}
        />
      </div>
      <Helmet>
        <title>Seven - Contact us</title>
        <meta
          name='description'
          content='Feel free to contact us via email and we will respond in short time. Makarska Riviera.'
        ></meta>
      </Helmet>
    </div>
  );
};

export { Contact };
