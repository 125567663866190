import * as FIREBASE from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDmzu9vKl05rzZvw66U12Ye7wO7lIH5tCI",
  authDomain: "rent-a-car-seven.firebaseapp.com",
  databaseURL: "https://rent-a-car-seven.firebaseio.com",
  projectId: "rent-a-car-seven",
  storageBucket: "rent-a-car-seven.appspot.com",
  messagingSenderId: "113577710485",
  appId: "1:113577710485:web:a0b468df311aeec0b45c9c",
  measurementId: "G-VQNNJLFC4R"
};

if (!FIREBASE.apps.length) {
  FIREBASE.initializeApp(firebaseConfig);
}
const firebase = FIREBASE;
const storage = firebase.storage();

export { firebase, storage };
