import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const Login = ({ handleLogin, error }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className="fullscreen">
      <div className="login-container">
        <h1>{t("login.title")}</h1>
        <p>{t("login.message")}</p>

        {error && <p className="error-message">{error}</p>}
        <input
          type="text"
          value={email}
          onChange={event => {
            setEmail(event.target.value);
          }}
          placeholder="example@seven.com"
        />
        <input
          type="password"
          value={password}
          onChange={event => {
            setPassword(event.target.value);
          }}
          placeholder={t("login.passwordPlaceholder")}
        />

        <button onClick={() => handleLogin(email, password)}>
          {t("login.buttonText")}
        </button>
      </div>
    </div>
  );
};
