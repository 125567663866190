import React, { useState } from "react";
import ArrowDown from "static/SVG/Reservation/ArrowDownGray.svg";
import ArrowUp from "static/SVG/Reservation/ArrowUpGray.svg";

import "simplebar";
import "simplebar/dist/simplebar.css";

import { ReactSVG } from "react-svg";

export interface IDropdownInputProps {
  placeholder: string;
  options: string[];
  onChange: (value: string) => void;
  name: string;
  initialValue?: string;
}

const DropdownInput: React.FC<IDropdownInputProps> = ({
  placeholder,
  options,
  onChange,
  name,
  initialValue
}) => {
  const [selected, setSelected] = useState<string | null>(
    initialValue ? initialValue : null
  );
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const handleSelection = (option: string) => {
    setSelected(option);
    setIsCollapsed(true);
    onChange(option);
  };
  return (
    <div
      className={`dropdown-input ${isCollapsed ? "colapsed" : "visible"}`}
      tabIndex={0}
      onFocus={() => setIsCollapsed(false)}
      onBlur={() => setIsCollapsed(true)}
    >
      <div className="dropdown-selected">
        <input
          tabIndex={-1}
          name={name}
          value={selected ? selected : ""}
          placeholder={placeholder}
          readOnly
        />

        <ReactSVG src={isCollapsed ? ArrowDown : ArrowUp} />
      </div>
      <div
        className={`dropdown-options-container ${
          isCollapsed ? "collapsed" : "visible"
        }`}
        data-simplebar
      >
        <ul>
          {options.map((option: string, i: number) => (
            <li
              key={i}
              onClick={() => handleSelection(option)}
              className={`${option === selected ? "selected" : ""}`}
            >
              {option}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export { DropdownInput };
