import React, { useState, useEffect } from "react";
import { firebase } from "../../../Firebase";
import { Car } from "./Car";
import { Loading } from "components/Loading";
import { useTranslation } from "react-i18next";
import Logo from "static/SVG/Logo/Logo.svg";
import { ReactSVG } from "react-svg";

const Dashboard = ({}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [cars, setCars] = useState(null);

  useEffect(() => {
    const db = firebase.firestore();
    db.collection("cars")
      .get()
      .then(querySnapshot => {
        let carsFromDB = [];
        querySnapshot.forEach(doc => {
          const car = doc.data();
          carsFromDB.push({
            name: car.name,
            price: car.price,
            id: doc.id,
            image: car.image
          });
        });
        setCars([...carsFromDB]);
        setIsLoading(false);
      });
  }, []);
  if (isLoading) {
    return <Loading fullscreen background="#000" text="Loading..." />;
  }
  return (
    <div className="dashboard">
      <div className="header">
        <div className="logo">
          <ReactSVG src={Logo} />
        </div>
        <div className="signout">
          <button onClick={() => firebase.auth().signOut()}>
            {t("admin.dashboard.signout").toUpperCase()}
          </button>
        </div>
      </div>
      <div className="content">
        <h1 className="heading">{t("admin.dashboard.title")}</h1>
        <div className="cars-container">
          {cars.map((item, i) => (
            <div key={i}>
              <Car car={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { Dashboard };
