import React, { useEffect } from "react";

import AllDay from "static/SVG/Extras/247.svg";
import Booking from "static/SVG/Extras/Booking.svg";
import Discount from "static/SVG/Extras/Discount.svg";
import Driver from "static/SVG/Extras/Driver.svg";
import Dropoff from "static/SVG/Extras/Dropoff.svg";
import Gps from "static/SVG/Extras/Gps.svg";
import Pickup from "static/SVG/Extras/Pickup.svg";
import SCDW from "static/SVG/Extras/SCDW.svg";
import { ReactSVG } from "react-svg";

import { useTranslation } from "react-i18next";

export interface IServicesProps {}

const Services: React.FC<IServicesProps> = ({}) => {
  const { t } = useTranslation();

  return (
    <div className="fullWidth">
      <div className="services-container">
        <div className="services-content">
          <h2>
            {t("services.weAlsoOfferOther")}{" "}
            <span>{t("services.extraServices")}</span> {t("services.and")}{" "}
            <span>{t("services.extraCarEquipment")}</span>{" "}
            {t("services.basedOnYourNeedsAndWishes")}
          </h2>
          <div className="services-desktop">
            <div className="service">
              <ReactSVG src={Gps} />
              <span>{t("services.gps")}</span>
            </div>
            <div className="service">
              <ReactSVG src={Booking} />

              <span>{t("services.onlineBooking")}</span>
            </div>
            <div className="service">
              <ReactSVG src={Dropoff} />

              <span>{t("services.flexDropOff")}</span>
            </div>
            <div className="service">
              <ReactSVG src={Pickup} />

              <span>{t("services.flexPickUp")}</span>
            </div>
            <div className="service">
              <ReactSVG src={Discount} />

              <span>{t("services.longTermDiscount")}</span>
            </div>
            <div className="service">
              <ReactSVG src={SCDW} />

              <span>{t("services.SCDW")}</span>
            </div>
            <div className="service">
              <ReactSVG src={AllDay} />

              <span>{t("services.247")}</span>
            </div>
            <div className="service">
              <ReactSVG src={Driver} />

              <span>{t("services.hireDriver")}</span>
            </div>
          </div>

          <div className="services-mobile">
            <div className="row">
              <div className="service">
                <ReactSVG src={Gps} />
                <span>{t("services.gps")}</span>
              </div>
              <div className="service">
                <ReactSVG src={Booking} />

                <span>{t("services.onlineBooking")}</span>
              </div>
              <div className="service">
                <ReactSVG src={Dropoff} />

                <span>{t("services.flexDropOff")}</span>
              </div>
            </div>
            <div className="row">
              <div className="service">
                <ReactSVG src={Pickup} />

                <span>{t("services.flexPickUp")}</span>
              </div>
              <div className="service">
                <ReactSVG src={Discount} />

                <span>{t("services.longTermDiscount")}</span>
              </div>
              <div className="service">
                <ReactSVG src={SCDW} />

                <span>{t("services.SCDW")}</span>
              </div>
            </div>
            <div className="row">
              <div className="service">
                <ReactSVG src={AllDay} />

                <span>{t("services.247")}</span>
              </div>
              <div className="service">
                <ReactSVG src={Driver} />

                <span>{t("services.hireDriver")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Services };
