import React from "react";
import Vito from '../../static/Photo/VitoTransfer.png'

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface ITransfersProps {}

const Transfers: React.FC<ITransfersProps> = ({}) => {
  const { t } = useTranslation();

  return (
    <div className="fullWidth">
      <div className="transfers-container">
        <div className="transfers-content">
          <img className="image" src={Vito} alt="Van"/>
          <div className="text">
              <h2>

          {t("transfer.ifYouNeed")+" "} <span className="highlight">{t("transfer.transferForUpToEightPerson")+" "}</span>{t("transfer.justLetUsKnow")}
              </h2>
              <button><Link to="/contact">{t("transfer.contactUs")}</Link></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Transfers };
